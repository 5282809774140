import { Typography } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

const { Text } = Typography

const ShadowDiv = styled.div`
  box-shadow: 3px 3px 14px 0px rgba(224, 224, 224, 1);
  padding: 5px;
`

const ProfilePicture = styled(ShadowDiv)`
  max-width: 120px;
  margin-bottom: 0.45rem;
`

const Name = styled.div`
  text-align: center;
  max-width: 120px;
`
export interface Props {
  name: string
  title: string
  photo: FC
}

const TeamMember: FC<Props> = ({ name, title, photo }) => {
  const TeamPhoto = photo

  return (
    <>
      <ProfilePicture>
        <TeamPhoto />
      </ProfilePicture>
      <Name>
        <Text>{name}</Text>
        <br />
        <Text type="secondary">{title}</Text>
      </Name>
    </>
  )
}
export default TeamMember
