import { Col, Row, Typography } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import AOOSImage from '../image/partners/aoos-image'
import AxiomImage from '../image/partners/axiom-image'
import GWImage from '../image/partners/gw-image'
import HRMImage from '../image/partners/hrm-image'
import IOOSImage from '../image/partners/ioos-image'
import NPRBImage from '../image/partners/nprb-image'
import PWSSImage from '../image/partners/pwss-image'

const { Title, Text } = Typography

const PartnerList = styled.div`
  margin-top: 1em;
  margin-bottom: 3em;
`

const LogoRow = styled(Row)`
  padding: 8px 0;
`

const Partners: FC = () => {
  return (
    <PartnerList>
      <Title level={2}>Partners</Title>
      <Text>
        Thank you to our partners who have helped refine our approach to
        scientific data management.
      </Text>

      <LogoRow gutter={16}>
        <Col span={4}>
          <AOOSImage />
        </Col>
        <Col span={4}>
          <GWImage />
        </Col>
        <Col span={4}>
          <NPRBImage />
        </Col>
        <Col span={4}>
          <AxiomImage />
        </Col>
      </LogoRow>
      <LogoRow gutter={16}>
        <Col span={4} offset={2}>
          <PWSSImage />
        </Col>
        <Col span={4}>
          <IOOSImage />
        </Col>
        <Col span={4}>
          <HRMImage />
        </Col>
      </LogoRow>
    </PartnerList>
  )
}
export default Partners
