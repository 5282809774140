import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { FC } from 'react'

const ShaneStSavageImage: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "team/s-stsavage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.image.childImageSharp.fluid} />
}

export default ShaneStSavageImage
