import { Col, Divider, Row, Typography } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import BrianStoneImage from '../components/image/team/bstone-image'
import ChrisTurnerImage from '../components/image/team/cturner-image'
import DaveImage from '../components/image/team/dave-image'
import IanGillImage from '../components/image/team/ian-image'
import RobBochenekImage from '../components/image/team/rob-image'
import RossMartinImage from '../components/image/team/ross-image'
import ShaneStSavageImage from '../components/image/team/shane-image'
import StaceyImage from '../components/image/team/stacey-image'
import VanessaRaymondImage from '../components/image/team/vanessa-image'
import Layout from '../components/layout'
import Partners from '../components/partners/Partners'
import SEO from '../components/seo'
import TeamMember from '../components/team/TeamMember'

const { Text, Paragraph } = Typography

const Title = styled.h1`
  margin-bottom: 0.2em;
`
const Content = styled.div`
  margin-top: 1.5em;
  margin-bottom: 0.2em;

  font-size: 1.2em;
`

const TeamRow = styled(Row)`
  margin-bottom: 1em;
`

const AboutUs: FC = () => (
  <Layout showHome={true}>
    <SEO title="About us" />
    <Title>About us</Title>
    <Content>
      <Paragraph>
        Ten years ago, we were wrestling with how to approach scientific data
        management. As members of the Axiom Data Science team, it was important
        to be able to preserve our partners’ research. These struggles led to
        the development of the Research Workspace.
      </Paragraph>
      <Paragraph>
        Today, the Research Workspace supports the efforts of many research
        teams who leverage its secure file storage, scientific metadata editor,
        Jupyter notebook integration, and data archiving capabilities. Our
        mission is to help teams deliver reproducible science.
      </Paragraph>
      <Text></Text>

      <Divider />
      <TeamRow>
        <Col span={8}>
          <TeamMember
            name="Rob Bochenek"
            title="Founder, CEO"
            photo={RobBochenekImage}
          />
        </Col>
        <Col span={8}>
          <TeamMember
            name="Stacey Buckelew"
            title="Data Coordinator"
            photo={StaceyImage}
          />
        </Col>
        <Col span={8}>
          <TeamMember
            name="Dave Foster"
            title="Sr Software Engineer"
            photo={DaveImage}
          />
        </Col>
      </TeamRow>
      <TeamRow>
        <Col span={8}>
          <TeamMember
            name="Ian Gill"
            title="Data Coordinator"
            photo={IanGillImage}
          />
        </Col>
        <Col span={8}>
          <TeamMember
            name="Ross Martin"
            title="Technical Lead"
            photo={RossMartinImage}
          />
        </Col>
        <Col span={8}>
          <TeamMember
            name="Vanessa Raymond"
            title="Product Owner"
            photo={VanessaRaymondImage}
          />
        </Col>
      </TeamRow>
      <TeamRow>
        <Col span={8}>
          <TeamMember
            name="Shane St Savage"
            title="Infrastructure"
            photo={ShaneStSavageImage}
          />
        </Col>
        <Col span={8}>
          <TeamMember
            name="Brian Stone"
            title="Sr Software Engineer"
            photo={BrianStoneImage}
          />
        </Col>
        <Col span={8}>
          <TeamMember
            name="Chris Turner"
            title="Data Librarian"
            photo={ChrisTurnerImage}
          />
        </Col>
      </TeamRow>
      <Divider />
      <Partners />
    </Content>
  </Layout>
)

export default AboutUs
